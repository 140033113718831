import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserGrp } from '../../interfaces/master-model-interface';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.scss'],
})
export class SidenavbarComponent implements OnInit, AfterViewInit {
  public sidebarItems: any[] = [];
  enterTimeout: any | undefined;
  leaveTimeout: any | undefined;
  nextTimeout: any | undefined;
  constructor(
    private Auth: AuthService,
    public router: Router,
    public window: Window
  ) { }
  async ngOnInit() {
    // this.sidebarItems = await this.Auth.GetSideMenu();
    // this.sidebarItems.forEach((e) => {
    //   e.MenuIconURL = `assets/icons/sidenav/${e.GroupName}.svg`;
    // });
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     const parents = document.querySelectorAll<HTMLElement>('.parent');
    //     const textContents: string[] = [];
    //     parents.forEach((child) => {
    //       textContents.push(child.children[0].children[1].textContent ?? '***');
    //       child.setAttribute('active', 'false');
    //     });
    //     this.sidebarItems.forEach((sideBarItem) => {
    //       sideBarItem.SubMenus.forEach((sub: any) => {
    //         if (event.url.includes(sub.MenuURL)) {
    //           textContents.find((value, i) => {
    //             if (value === sideBarItem.GroupName) {
    //               parents[i].setAttribute('active', 'true');
    //             }
    //           });
    //         }
    //       })
    //     })
    //   }
    // })
  }

  // ngAfterViewInit() {
  //   this.Auth.currentGrpSubject.subscribe(async (grp: UserGrp) => {
  //     this.sidebarItems = await this.Auth.GetSideMenu();
  //     this.sidebarItems.forEach((e) => {
  //       e.MenuIconURL = `assets/icons/sidenav/${e.GroupName}.svg`;
  //     });
  //   });
  // }

  ngAfterViewInit() {
    this.Auth.currentGrpSubject.subscribe(async (grp: UserGrp) => {
      let allSidebarItems = await this.Auth.GetSideMenu();

      // Filter to only keep MenuType === 1
      this.sidebarItems = allSidebarItems.filter((item: any) => item.MenuType === 1);

      // Set the MenuIconURL after filtering
      this.sidebarItems.forEach((e) => {
        e.MenuIconURL = `assets/icons/sidenav/${e.GroupName}.svg`;
      });
    });
  }



  click(index: number) {
    const parents = document.querySelectorAll<HTMLElement>('.parent');
    const sideNav = document.querySelector<HTMLElement>('.sidenav');
    if (
      parents[index].getAttribute('expanded') === 'false' &&
      sideNav?.classList.contains('pinned')
    ) {
      parents.forEach((child) => {
        child.setAttribute('expanded', 'false');
      });
      parents[index].setAttribute('expanded', 'true');
    } else {
      parents[index].setAttribute('expanded', 'false');
    }
  }

  hoverOnSide(e: MouseEvent, i: number) {
    function checkIfInViewport(hElement: HTMLElement) {
      var bounding = hElement.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        return true;
      } else {
        return false;
      }
    }
    const appRoot = document.querySelector<HTMLElement>('app-root');
    const sideNav = document.querySelector<HTMLElement>('.sidenav');
    e.stopPropagation();
    if (!sideNav?.classList.contains('pinned')) {
      const target = e.target as HTMLElement;

      // Get the bounding rectangle of target
      const rect = target?.getBoundingClientRect();

      //calculate origin of the element
      const originX = rect.left + rect.width / 2;
      const originY = rect.top + rect.height / 2;

      if (this.enterTimeout != undefined) {
        clearTimeout(this.enterTimeout);
        this.enterTimeout = undefined;
      }
      const hElement = document.createElement('div');
      hElement.classList.add('hover-element');
      // hElement.style.top = `${originY - (rect.height / 2 - 10)}px`;
      // hElement.style.left = `${originX + 36}px`;
      hElement.style.zIndex = `100`;
      this.sidebarItems[i].SubMenus.forEach((sub: any) => {
        const a = document.createElement('a');
        a.classList.add('subtitle');
        a.setAttribute(
          'style',
          `padding: 6px 10px;
            margin-left: 6px;
            text-decoration: none;
            font-size: 16px;
            color: var(--grey);
            font-family: "Rubik";
            display: block;
            border-left: 3px solid var(--border-color-75);
            cursor: pointer;`
        );
        if (
          this.router.isActive(sub.MenuURL, {
            paths: 'exact',
            queryParams: 'exact',
            fragment: 'ignored',
            matrixParams: 'ignored',
          })
        ) {
          a.style.backgroundColor = 'aliceblue';
          a.style.color = 'var(--infoblue';
          a.style.borderLeft = '3px solid var(--infoblue)';
        } else {
          a.onmouseover = () => {
            a.style.backgroundColor = 'var(--light)';
          };
          a.onmouseleave = () => {
            a.style.backgroundColor = 'white';
          };
        }
        a.addEventListener('click', () => {
          this.router.navigate([sub.MenuURL]);
          document.querySelector<HTMLElement>('.hover-element')?.remove();
        });

        a.textContent = `   ${sub.PrivilegeName}`;
        hElement.appendChild(a);
      });
      hElement.onmouseover = () => {
        clearTimeout(this.nextTimeout);
        this.nextTimeout = undefined;
        clearTimeout(this.enterTimeout);
        this.enterTimeout = undefined;
      };
      hElement.onmouseleave = () => {
        document.querySelector<HTMLElement>('.hover-element')?.remove();
      };
      if (this.nextTimeout != undefined) {
        clearTimeout(this.nextTimeout);
        this.nextTimeout = undefined;
        clearTimeout(this.enterTimeout);
        this.enterTimeout = undefined;
        document.querySelector<HTMLElement>('.hover-element')?.remove();
        target?.parentElement?.parentElement?.appendChild(hElement);
        if (!checkIfInViewport(hElement)) {
          var bounding = hElement.getBoundingClientRect();
          hElement.style.top = `calc( 50% - ${bounding.bottom - document.documentElement.clientHeight
            }px)`;
        }
      } else {
        this.enterTimeout = setTimeout(() => {
          target?.parentElement?.parentElement?.appendChild(hElement);
        }, 1000);
      }
    }
  }

  leaveOnSide(e: MouseEvent, i: number) {
    const target = e.target as HTMLElement;
    if (this.nextTimeout != undefined) {
      clearTimeout(this.nextTimeout);
      this.nextTimeout = undefined;
      clearTimeout(this.enterTimeout);
      this.enterTimeout = undefined;
    }
    this.nextTimeout = setTimeout(() => {
      document.querySelector<HTMLElement>('.hover-element')?.remove();
      clearTimeout(this.nextTimeout);
      this.nextTimeout = undefined;
      clearTimeout(this.enterTimeout);
      this.enterTimeout = undefined;
    }, 1000);
  }
  checkActive(url: string, i: number) {
    return this.sidebarItems[i].SubMenus.some((e: any) => url == e.MenuURL);
  }


  handleClick(url: string) {
    const currentModuleID = this.Auth.currentGrpSubject.value.ModuleID;

    if (currentModuleID === 3 && this.router.url.endsWith(url)) {
      window.location.reload();
    }
  }


}
